.linksManage {
  margin-top: 1rem;

  .headerOprate {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    Button {
      // margin: 0 1rem;
      // flex: 1;
      width: 48%;
    }
  }
}
.list {
  .item {
    position: relative;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0px 0px 3px 0px #d7dce1;
    margin-bottom: 1rem;
    .body {
      display: flex;
      flex-direction: row;
      align-items: stretch;

      .dragHandle {
        padding: 1rem;
        border-right: 1px solid rgb(215, 220, 225);
        color: rgb(172, 181, 191);
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .main {
        flex: 1;
        padding: 0.5rem 0 0.5rem 0.5rem;
        display: flex;
        flex-direction: column;

        // textarea:focus,
        // input:focus {
        //   outline: none;
        //   width: 100%;
        //   color: rgb(61, 68, 75);
        //   font-size: 1rem;
        //   font-weight: bold;
        //   border: none;
        //   padding: 0px;
        //   margin: 0px auto;
        //   font-family: inherit;
        //   letter-spacing: normal;
        //   background: transparent;
        //   box-sizing: border-box;
        //   resize: none;
        //   line-height: 20px;
        //   overflow: auto;
        //   height: auto;
        //   font-weight: 700;
        // }
        span,
        p {
          cursor: pointer;
          font-weight: 700;
          width: 100%;
          font-size: 1rem;
        }
        .linkHeader {
          height: 4rem;
          display: flex;
          align-items: center;
          p {
            text-align: center;
            margin: 0;
            color: #686d73;
          }
          input:focus {
            outline: none;
            width: 100%;
            color: rgb(61, 68, 75);
            font-size: 1rem;
            font-weight: bold;
            border: none;
            padding: 0px;
            margin: 0px auto;
            font-family: inherit;
            letter-spacing: normal;
            background: transparent;
            box-sizing: border-box;
            resize: none;
            line-height: 20px;
            overflow: auto;
            height: auto;
            font-weight: 700;
            text-align: center;
          }
        }
        .linkTitle {
          // margin-bottom: 0.5rem;
          padding: 4px;
          span {
            color: #686d73;
          }
        }
        .require {
          border: 1px solid #f00;
          border-radius: 4px;
        }
        .link {
          padding: 4px;
          span {
            color: #3d444b;
            font-weight: normal;
          }
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
      }
    }
    .footer {
      padding: 1rem;
      border-top: 1px dashed #d7dce1;
      p {
        text-align: center;
      }
      button {
        width: 40%;
      }
    }
  }
}
