.center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

h1 {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 2rem;
}

.maxW {
    max-width: 1000px;
    margin: 0 auto;
}

.link {
    text-decoration: underline;
    cursor: pointer;
}

a {
    text-decoration: none;
    // color: #000;
}

.part1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 0;
    background-color: white;
    h1 {
        color: #8e1ebc;
    }

    .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .avatar {
            // margin-bottom: 0.5rem;
        }
        p {
            font-size: 0.8rem;
            color: #000;
        }
    }

    .right {
        flex: 1;
        background-color: #000000;
        margin-left: 1rem;
        border-radius: 2rem;
        padding: 1.5rem;

        p {
            &.name {
                color: #8e1ebc;
                margin-bottom: 1rem;
            }
            color: white;
            margin: 0;
            font-size: 1rem;
        }

        .Btn {
            background-color: #282a2f;
            padding: 0.4rem 1.5rem;
            color: white;
            border-radius: 4px;
            margin-top: 2rem;
            cursor: pointer;
            text-align: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: 0.8rem;
            border: 1px solid #3c3d42;

            img {
                width: 1.5rem;
                height: 1.5rem;
                margin-right: 1rem;
            }
        }
    }

    .loginPanel {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }
}

.part3 {
    padding: 3rem 0;
    background-color: #f8f8f8;
    .hotuser {
        text-align: center;
        margin-top: 2rem;
        img {
            width: 100%;
            height: auto;
            border: 5px solid #000;
            border-radius: 30px;
            margin-bottom: 0.5rem;
        }
        p {
            font-size: 0.9rem;
            color: #000;
        }
    }
}

.part2 {
    padding: 3rem 0;
    // background-color: #f8f8f8;
    .featureItem {
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon {
            width: 6rem;
            height: 6rem;
            // background-color: #c4c4c4;
            border-radius: 8px;
            margin-bottom: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .name {
            font-size: 1rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
            text-align: center;
        }

        .desc {
            font-size: 0.8rem;
            text-align: center;
            max-width: 15rem;
        }
    }
}
