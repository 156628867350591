html,
body {
    background-color: white !important;
}

.banner {
    height: 220px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.community_avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -2rem;
}

.community_name {
    margin: 2rem 0;
    text-align: center;
}

.gird {
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
}

.community_description {
    margin-top: 0.5rem;
    * {
        font-size: 1rem !important;
    }
}

.square {
    width: 100%;
    position: relative;
    &:after {
        content: "";
        display: block;
        padding-top: 100%;
    }
    .body {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
    img {
        max-width: 100%;
        height: 100%;
    }
}

.table {
    border: 1px solid #dee2e6;
    border-radius: 4px;
    .tableHeader {
        padding: 0 8px;
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .tableBody {
        overflow-x: auto;
        padding-bottom: 5px;

        > .tableRow {
            &:first-child {
                .tableCel {
                    background-color: #f8f8f8;
                    height: 40px;
                }
            }
        }

        .tableRow {
            white-space: nowrap;
            // cursor: pointer;

            .tableCel {
                border-top: 1px solid #dee2e6;
                height: 60px;
                display: inline-flex;
                align-items: center;
                padding: 0 8px;
                vertical-align: middle;
                &:nth-child(1) {
                    // min-width: 12rem;
                    width: 50%;
                }
                &:nth-child(2) {
                    // min-width: 15rem;
                    width: 50%;
                }
                // &:nth-child(3) {
                //     min-width: 5rem;
                //     // justify-content: right;
                //     width: 20%;
                // }

                .userAvatar {
                    // width: 50px;
                    // height: 50px;
                    // background-color: #333;
                    // border-radius: 8px;
                    margin-right: 0.5rem;
                }
                .userInfo {
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;
                    flex: 1;
                    h5,
                    p {
                        margin: 0;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                .nft {
                    width: 40px;
                    height: 40px;
                    background-color: #333;
                    border-radius: 8px;
                    margin-right: 0.5rem;
                }
            }
        }
    }
}

.tokenPreviewItem {
    width: 40px;
    height: 40px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 5px;
}

.blockA {
    color: #000;
    text-decoration: none;
}
