html,
body {
  background-color: #f8f8f8 !important;
}

@font-face {
  font-family: "Archive-Regular";
  src: url("fonts/Archive-Regular.ttf");
}

.fontArchivRegular {
  font-family: "Archive-Regular";
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.w100 {
  width: 100%;
  overflow: hidden;
}

.safe-distance {
  height: 100px;
}

.react-viewer-mask {
  background-color: rgba(55, 55, 55, 1) !important;
}

.mask {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
}

@media (min-width: 1200px) {
  .container {
    max-width: 900px !important;
  }
}

.page-container {
  max-width: 900px;
  margin: 0 auto;
}

.mw-700 {
  max-width: 700px !important;
  margin: 0 auto;
}
