.header {
    background-color: white;
    height: 3.5rem;
    box-shadow: 0px 0px 11px #e3dede;
    // z-index: 100;
    position: relative;
    padding: 0 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .logo {
        display: flex;
        flex-direction: row;
        align-items: center;
        a {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-decoration: none;
            :hover {
                color: #000;
            }
        }
        img {
            width: 2.5rem;
            height: 2.5rem;
            margin-right: 0.5rem;
        }
        span {
            color: #000;
            font-weight: bold;
            font-size: 1.2rem;
        }
    }
    .profile {
        position: relative;
        cursor: pointer;
        img {
            width: 3rem;
            height: 3rem;
        }
    }
    .avatarPlaceholder {
        width: 3rem;
        height: 3rem;
        background-color: #dcdcdc;
        border-radius: 100%;
    }
}
.showMobilePreviewBtn {
    position: fixed;
    left: 50%;
    bottom: 40px;
    transform: translate(-50%, 0);
    background-color: #737373;
    padding: 1rem 2rem;
    border-radius: 10px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
