.badge {
    padding: 0rem 0.4rem;
    border-radius: 1rem;
    font-size: 0.5rem;
    color: white;
}

.dot {
    width: 1rem;
    height: 1rem;
}
