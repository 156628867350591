.header {
    background-color: white;
    height: 3.5rem;
    box-shadow: 0px 0px 11px #e3dede;
    // z-index: 100;
    position: relative;
    padding: 0 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .logo {
        display: flex;
        flex-direction: row;
        align-items: center;
        a {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-decoration: none;
            :hover {
                color: #000;
            }
        }
        img {
            width: 2.5rem;
            height: 2.5rem;
            margin-right: 0.5rem;
        }
        span {
            color: #000;
            font-weight: bold;
            font-size: 1.2rem;
        }
    }
    .haslogin {
        position: relative;
        cursor: pointer;
    }

    .notlogin {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        position: relative;
        .btn {
            background: #8e20bc;
            padding: 0.4rem 1rem;
            color: white;
            border-radius: 20px;
            margin-left: 1rem;
        }
    }
}

.dropItem {
    cursor: pointer;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.9rem;

    img {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem;
    }
}
