.mobile {
    width: 352px;
    height: 724px;
    background-color: white;
    display: block;
    margin: 0 auto;
    position: fixed;
    overflow: hidden;
    top: 58%;
    left: 82%;
    transform-origin: top left;
    // transform: translateY(-50%) translateX(-50%) translateZ(0);
    transform: scale(0.7) translateX(-50%) translateY(-50%) translate3d(0px, 0px, 0px);
    border: 10px solid black;
    border-radius: 50px;

    .container {
        // overflow-y: auto;
        height: 100%;
        overflow: hidden;
    }
}

.desktop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
    // z-index: 10000;
    .container {
        // margin-top: 2rem;
        // box-sizing: border-box;
        // overflow-y: auto;
        height: 100%;
        background-color: white;
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;
        overflow: hidden;
    }
}
