.profile {
    // padding: 1rem;
    margin-bottom: 1rem;
    textarea,
    input,
    select {
        width: 100%;
        border: 1px solid rgb(215, 220, 225);
        font-size: 1rem;
        border-radius: 4px;
        padding: 0.5rem;
    }

    h4 {
        margin-top: 0.7rem;
    }

    .subtitle {
        // font-style: 16px;
        // color: #737373;
        margin: 0;
        margin-bottom: 0.5rem;
    }
}

.search {
    width: 100%;
    border: 1px solid rgb(215, 220, 225);
    font-size: 1rem;
    border-radius: 4px;
    padding: 0.5rem;
}

.container {
    background: white;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    padding: 1rem;
}
.avatarConfig {
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    .avatarMenus {
        flex: 1;

        margin-left: 1rem;
        Button {
            width: 100%;
            margin: 0.3rem 0;
            &.select {
                background-color: #8e20bc;
                border-color: #8e20bc;
            }
        }
    }
    p {
        padding-top: 0.5rem;
        text-align: center;
    }
}

.chooseAvatarType {
    display: flex;
    align-items: center;
    height: 8rem;
    cursor: pointer;
    &:first-child {
        border-bottom: 1px solid #eee;
    }
    .center {
        margin: 0 1rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex: 1;
        p{
            margin: 0;
        }
    }
}

.square {
    width: 100%;
    position: relative;
    cursor: pointer;
    &:after {
        content: "";
        display: block;
        padding-top: 100%;
    }
    .body {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #f8f8f8;
        border: 1px solid #dcdcdc;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
        }
    }
    .remove {
        position: absolute;
        top: -0.5rem;
        right: -0.5rem;
        color: white;
        width: 1.5rem;
        height: 1.5rem;
        background: #000;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.row {
    margin-bottom: 1rem;

    .border_bottom {
        border: none;
        border-bottom: 1px solid #dcdcdc;
    }
}

.upload {
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 5rem;
        height: 5rem;
    }
}

.topnftTitle {
    margin-bottom: 1rem;
}
.avatarloading{
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}