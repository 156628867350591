.avatar {
    position: relative;
    display: inline-block;
    img {
        width: 100%;
        height: 100%;
    }

    .badge {
        position: absolute;
        top: -0.7rem;
        border: 2px solid white;
        border-radius: 1rem;
    }
}
