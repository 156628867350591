.appearance {
    margin-bottom: 1rem;
    h4 {
        margin-top: 0.7rem;
    }
    .subtitle {
        font-style: 16px;
        color: #737373;
        margin: 0;
        // margin-bottom: 0.5rem;
        text-align: center;
        margin-top: 0.7rem;
    }
}

.container {
    background: white;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    padding: 1rem;
}

.square {
    width: 100%;
    position: relative;
    cursor: pointer;
    &:after {
        content: "";
        display: block;
        padding-top: 100%;
    }
    .body {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #f8f8f8;
        border: 1px solid #dcdcdc;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        p {
            margin: 0;
            font-size: 30px;
        }

        p.name {
            font-size: 14px;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            text-align: center;
            padding: 0 5px;
            opacity: 0.5;
        }
    }
}

.row {
    // margin-bottom: 1rem;

    .border_bottom {
        border: none;
        border-bottom: 1px solid #dcdcdc;
    }
}

.upload {
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 5rem;
        height: 5rem;
    }
}

.btnstyle {
    height: 45px;
    border-style: solid;
    border-width: 2px;
    border-color: #aa57cd;
    width: 100%;
    background: #aa57cd;
    margin-bottom: 10px;
    margin-top: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &.outline {
        background: white !important;
    }

    &.rounded {
        border-radius: 4px !important;
    }

    &.roundedCircle {
        border-radius: 50px !important;
    }

    .selected {
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        color: #fff;
    }
}

.btnColorPicker {
    display: flex;
    align-items: center;
    position: relative;

    .colorPickerHandle {
        width: 20px;
        height: 20px;
        border: 1px solid #737373;
        border-radius: 4px;
        margin-left: 10px;
    }
}

.selectColor {
    position: absolute;
    // width: 400px;
    top: 100%;
    left: 0;
    z-index: 2;
}
.selectColorMask {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}
