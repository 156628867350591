.photo {
    // background-color: rgb(255, 255, 255);
    // border: 1px solid rgb(229, 232, 235);
    border-style: solid;
    border-width: 1px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    .square {
        width: 100%;
        position: relative;
        &:after {
            content: "";
            display: block;
            padding-top: 100%;
        }
        .container {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }
        img {
            max-width: 100%;
            height: 100%;
        }
    }

    .info {
        padding: 0.5rem;
        width: 100%;
        overflow: hidden;
    }
    p {
        // text-align: center;
        font-size: 12px;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }
}

.collectioinInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    p {
        flex: 1;
    }
}

.viewCollection{
    font-size: 12px;
    width: 2rem;
    height: 2rem;
    // background-color: #8E1EBC;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 5px #323131;
    color: white;
}