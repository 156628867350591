
.checkedIcon {
    position: absolute;
    top: -2.9rem;
    right: -2.9rem;
    width: 5rem;
    height: 5rem;
    font-size: 25px;
    color: #8e20bc;
    z-index: 1;
    background: #eee;
    transform: rotate(45deg);
    display: flex;
    justify-content: center;
    align-items: end;

    svg {
        transform: rotate(-45deg);
    }
}
