.container {
    margin: 0 auto;
    width: 100%;
    max-width: 680px;
    padding: 16px;
    position: relative;
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    h3 {
        margin-top: 1rem;
        font-weight: 700;
        font-size: 16px;
        line-height: 1.5;
        text-overflow: ellipsis;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
    }
}
.bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
}
.profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
}

.wallet {
    font-size: 12px;
    padding: 4px 8px;
    border: 1px solid rgb(229, 232, 235);
    border-radius: 16px;
    margin-top: 1rem;
    cursor: pointer;
    word-break: break-all;
}

.btnstyle {
    height: 45px;
    border-style: solid;
    border-width: 2px;
    border-color: #aa57cd;
    width: 100%;
    background: #aa57cd;
    margin-bottom: 10px;
    margin-top: 10px;

    &.outline {
        background: white !important;
    }

    &.rounded {
        border-radius: 4px !important;
    }

    &.roundedCircle {
        border-radius: 50px !important;
    }
}

.linkitem {
    margin-bottom: 16px;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    position: relative;
    padding: 16px 0;
    width: 100%;
    box-sizing: border-box;
    vertical-align: middle;
    text-decoration: none;

    border-style: solid;
    border-width: 2px;
    border-color: #aa57cd;
    background: #aa57cd;
    text-align: center;
    // &:hover {
    //     background: white;
    //     color: #8e1ebc;
    // }

    &.outline {
        background: white !important;
    }

    &.fill {
        background: unset;
    }

    &.rounded {
        border-radius: 4px !important;
    }

    &.roundedCircle {
        border-radius: 50px !important;
    }
}

.linkTitle {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
    margin: 1.5rem 0 1rem 0;
}

.bio {
    margin-bottom: 1em;
    text-align: center;
    line-height: 1.5;
    font-size: 14px;
}

.nftDetail {
    p {
        margin: 0;
    }
    .image {
        width: 100%;
        height: 20rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #263840;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .nftName {
        font-weight: 600;
        color: #000;
        font-size: 1.1rem;
        margin: 1rem 0;
    }

    .row {
        margin-bottom: 1.5rem;
        .ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 90%;
        }
        a {
            text-decoration: none;
        }
        .title {
            font-weight: 600;
            color: #000;
            font-size: 1rem;
            margin-bottom: 0.4rem;
        }
        .subtitle {
            font-weight: 600;
            color: #000;
            font-size: 1rem;
        }
        .text {
            font-size: 0.9rem;
            color: rgb(15, 20, 25);
        }

        .userRow {
            display: flex;
            flex-direction: row;
            align-items: center;
            img {
                width: 3rem;
                height: 3rem;
                margin-right: 0.7rem;
            }
            .center {
                flex: 1;
                overflow: hidden;
            }
        }
    }

    .property {
        margin-bottom: 0.4rem;
        font-size: 0.9rem;
        width: 100%;
        overflow: hidden;
        .key {
            color: rgb(83, 100, 113);
        }
        .val {
            color: rgb(15, 20, 25);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
        }
        .ContractAddress {
            display: flex;
            flex-direction: row;
            align-items: center;
            .val {
                flex: 1;
            }
            .key {
                margin-right: 1rem;
            }
        }
    }
}

.enableLoadTokens {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    cursor: pointer;
    span {
        margin-right: 0.5rem;
    }
}

.shareItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
        font-weight: bold;
        margin-left: 1rem;
    }
    img {
        width: 2rem;
        height: 2rem;
    }
}

.tooltip {
    position: relative;

    &:hover {
        &::after {
            position: absolute;
            width: 50px;
            content: "Copy";
            background-color: #000;
            color: white;
            left: 50%;
            margin-left: -25px;
            top: -18px;
            text-align: center;
            border-radius: 5px;
            padding: 3px 0;
            font-size: 14px;
        }
        &::before {
            position: absolute;
            width: 20px;
            height: 20px;
            content: "";
            background-color: #000;
            left: 50%;
            margin-left: -10px;
            top: -10px;
            transform: rotate(45deg);
        }
    }
}

.logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-top: 50px;
    text-decoration: none;
    img {
        width: 2rem;
        height: 2rem;
    }

    span {
        font-size: 1.5rem;
        font-weight: bold;
        margin-left: 0.3rem;
    }
}

.avatar {
    position: relative;
    cursor: pointer;
}

.mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: auto;
}

.description{
    *{
        font-size: 0.9rem !important;
    }
}
