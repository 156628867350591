.tab {
    border-bottom: 1px solid #dcdcdc;
    background-color: #FFFFFF;
    .container {
        display: flex;
        .item {
            cursor: pointer;
            height: 4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-right: 2rem;

            &.active {
                &::after {
                    content: "";
                    position: absolute;
                    left: -5px;
                    right: -5px;
                    bottom: -1px;
                    height: 6px;
                    border-radius: 20px;
                    background-color: #8e20bc;
                }
            }
        }
    }
}
