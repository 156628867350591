.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
}
.fill {
    flex: 1;
}
.fullScreen {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
    height: unset;
}
