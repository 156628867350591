.link {
    border-bottom: 1px solid #dcdcdc;
    background-color: #ffffff;
    .body {
        min-height: 4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .dropmenu {
        position: relative;
    }
    p {
        margin: 0;
        word-break: break-all;
        font-size: 14px;
    }
}

.shareItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
        font-weight: bold;
        margin-left: 1rem;
    }
    img {
        width: 2rem;
        height: 2rem;
    }
}

.mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    cursor: auto;
}
